<template>
  <el-dialog
    :visible.sync="staffDepartmentShow"
    :before-close="cancel"
    title="可见范围"
    :modal-append-to-body="false"
    width="500px"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForms"
      label-width="100px"
      @keyup.enter.native="_submiAddGroup()"
    >
      <el-form-item label="可见范围" prop="useType">
        <VisibilityRange
        ref="visibilityRange"
        pageType="file"
        :courseInfo="dataForm"
        />
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="cancel">取消</el-button>
      <el-button type="primary" @click="_submiAddGroup()">确认</el-button>
    </div>
  </el-dialog>
</template>
<script>
import VisibilityRange from '@/components/visibilityRange/index.vue'
import { editListApi } from '@/api/knowledge'
export default {
  components: {
    VisibilityRange
  },
  props: {
    staffDepartmentShow: {
      type: Boolean,
      default: false
    },
    currentFile: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      dataForm: {
        useUserIds: [],
        useDeptIds: [],
        useType: 2
      },
      dataRule: {
        useType: {
          required: true,
          message: '请选择可见范围',
          trigger: ['blur']
        }
      }
    }
  },
  created () {
    this.dataForm = this.currentFile
  },
  methods: {
    cancel () {
      this.$parent.staffDepartmentShow = false
    },
    _submiAddGroup () {
      // 分组保存
      this.$refs.dataForms.validate((valid) => {
        if (valid) {
          const viewDeptIds = []
          const viewUserIds = []
          const viewDeptNames = []
          const viewUserNames = []
          if (this.dataForm.useType == 2) {
            if (!this.dataForm.useUserIds.length && !this.dataForm.useDeptIds.length) {
              this.$message.error('请选择可见范围！')
              return
            }

            this.dataForm.useUserIds.forEach(item => {
              viewUserNames.push(item.name)
              viewUserIds.push(item.id)
            })
            this.dataForm.useDeptIds.forEach(item => {
              viewDeptNames.push(item.name)
              viewDeptIds.push(item.id)
            })
          }
          editListApi({
            ...this.currentFile,
            viewUserNames: viewUserNames.length ? viewUserNames.toString() : '',
            viewDeptNames: viewDeptNames.length ? viewDeptNames.toString() + ',' : '',
            viewUserIds: viewUserIds.length ? viewUserIds.toString() + ',' : '',
            viewDeptIds: viewDeptIds.length ? viewDeptIds.toString() + ',' : ''
          }).then(res => {
            if (res.code === 200) {
              this.$parent.getQuery()
              this.$parent.staffDepartmentShow = false
            }
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.radius {
  width: 93%;
  background: #f6f7f9;
  border-radius: 4px 4px 4px 4px;
  padding: 12px;
  margin-top: 8px;
  .radiu-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #333333;
    font-size: 14px;

    img {
      width: 16px;
      height: 16px;
    }
  }
}
.tag-content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.button-new-tag {
  padding-top: 0;
  padding-bottom: 0;
  width: 78px;
  height: 28px !important;
  line-height: 12px!important;
  text-align: center;
  margin-bottom: 10px;
}
.tag-item {
  margin-right: 10px;
  width: 78px;
  height: 28px;
  line-height: 27px;
  text-align: center;
  margin-bottom: 10px;
}
.add-group {
  display: flex;
  justify-content: center;
  margin-top: 12px;
  .add-group-btn {
    width: 102px;

    border: 1px solid #0089ff;
    font-size: 14px;
    color: #0089ff;
  }
}
.add-group-name {
  margin-bottom: 10px;
}
/deep/.el-dialog__body {
  max-height: 420px;
  overflow: auto;
}
</style>
