<template>
  <div class="resourceManage fx-col fx-1">
    <template v-if="!uploadFileShow">
      <el-card>
        <div slot="header">
          <el-form
            class="form-item-no-mb"
            ref="searchForm"
            inline
            :model="searchForm"
            size="small"
          >
            <el-form-item label="文件名">
              <el-input
                v-model="searchForm.fileName"
                clearable
                placeholder="请输入文件名"
              ></el-input>
            </el-form-item>
            <el-form-item label="文件类型">
              <el-select v-model="searchForm.fileType" clearable placeholder="请选择">
                <el-option
                  v-for="item in fileTypeList"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="创建时间">
              <el-date-picker
                v-model="searchForm.time"
                :default-time="['00:00:00', '23:59:59']"
                type="datetimerange"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button @click="_reset">重置</el-button>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="getQuery(1)">查询</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div>
          <el-button type="primary" @click="_uploadFileHandle"
            >上传文件</el-button
          >
          <el-button class="add-group-btn" :style="{'--lock-btn': $store.state.settings.theme}" @click="_selectData"
            >从资料库选择</el-button
          >
          <el-button class="delet-group-btn add-group-btn" :style="{'--lock-btn': $store.state.settings.theme}" @click="deleteTable('',1)"
            >删除</el-button
          >
        </div>
      </el-card>
      <div class="fx-row mt16 bac-fff fx-1">
        <!-- 资源分组 -->
        <ResourceGrouping
          title="知识库分组"
          :treeData="treeData"
          :defaultProps="defaultProps"
          @clickGroupNode="_clickGroupNode"
          @editGroupHandle="_editGroupHandle"
          @deleteGrouphandle="_deleteGrouphandle"
          @addGroupHandle="_addGroupHandle"
        />
        <!-- 列表 -->
        <div class="w200 fx-1 knowledgeTable" style="padding-top:8px;">
          <el-table
            :header-cell-style="cellHeaderStyleTwo"
            :cell-style="cellStyleTwo"
            class="index-list-table"
            :data="tableData"
            height="calc(100vh - 350px)"
            ref="multipleTable"
            row-key="id"
            @selection-change="handleSelectionChange"
          >
          <el-table-column
          :reserve-selection="true"
            type="selection"
            width="55">
          </el-table-column>
          <el-table-column prop="fileName" label="文件名称" min-width="200" >
              <template slot-scope="{row}">
                <el-popover trigger="hover" placement="top">
                  <span style="font-size: 12px;color: #303133;" > {{ row.fileName }}</span>
                  <div slot="reference" class="hover-title">
                    {{ row.fileName }}
                  </div>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column prop="groupName" label="所属分组"width="180">
              <template slot-scope="{row}">{{row.groupName||'—'}}</template>
            </el-table-column>
            <el-table-column prop="useType" label="可见范围" width="180">
              <template slot-scope="{row}">
                <el-popover trigger="hover" placement="top">
                  <span style="font-size: 12px;color: #303133;" >
                    <span v-if="row.useType == 1">全部人员</span>
                    <span v-else-if="row.viewDeptNames&&row.viewUserNames">
                      <span>{{row.viewDeptNames}}</span>
                      <span>{{row.viewUserNames}}</span>
                    </span>
                    <span v-else>
                      <span>{{row.viewDeptNames?row.viewDeptNames+'—':''}}</span>
                      <span>{{row.viewUserNames?row.viewUserNames:'—'}}</span>
                    </span>
                  </span>
                  <div slot="reference" class="hover-title">
                    <span v-if="row.useType == 1">全部人员</span>
                  <span v-else-if="row.viewDeptNames&&row.viewUserNames">
                    <span>{{row.viewDeptNames}}</span>
                    <span>{{row.viewUserNames}}</span>
                  </span>
                  <span v-else>
                    <span>{{row.viewDeptNames?row.viewDeptNames+'—':''}}</span>
                    <span>{{row.viewUserNames?row.viewUserNames:'—'}}</span>
                  </span>
                  </div>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column prop="downloadFlag" label="支持下载" width="100">
              <template slot-scope="{row}">{{row.downloadFlag == 1?'是':'否'}}</template>
            </el-table-column>
            <el-table-column
              prop="fileType"
              label="文件类型"
              width="100"
            >
            <template slot-scope="{row}" >{{row.fileTypeValue||'—'}}</template>
          </el-table-column>
            <el-table-column prop="size" label="文件大小" width="100">
              <template slot-scope="{row}">{{row.size?bytesToSize(row.size):'—'}}</template>
            </el-table-column>
            <el-table-column prop="fileDuration" label="文件时长" width="100">
              <template slot-scope="{row}">{{row.fileDuration?formatSeconds(row.fileDuration):'—'}}</template>
            </el-table-column>
            <el-table-column prop="createName" label="创建人" width="100">
              <template slot-scope="{row}">{{row.createName||'—'}}</template>
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间" width="180">
              <template slot-scope="{row}">{{row.createTime||'—'}}</template>
            </el-table-column>
            <el-table-column label="操作" width="220" fixed="right" class="option-btn">
              <template slot-scope="{row}">
                <el-button type="text" class="text-btn mr24" @click="_editHandle(row)"
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  class="text-btn mr24"
                  @click="_choosedDepartment(row)"
                  >可见范围</el-button
                >
                <el-dropdown>
                  <el-button
                    type="text"
                    class="text-btn"
                    style="margin-left: 10px"
                    >更多<i class="el-icon-arrow-down"></i
                  ></el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="downloadFile(row)">下载</el-dropdown-item>
                    <el-dropdown-item @click.native="_previewFileHandle(row)"
                      >预览</el-dropdown-item
                    >
                    <el-dropdown-item @click.native="deleteTable(row)"
                      >删除</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            class="fx-row-end pt16"
            @size-change="_handleSizeChange"
            @current-change="_handleCurrentChange"
            :current-page="searchForm.page"
            :page-sizes="[10, 20, 30, 40, 50]"
            :page-size="searchForm.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="tableTotal"
          >
          </el-pagination>
        </div>
      </div>
    </template>
      <!-- 列表预览 -->
      <PreviewFile
      v-if="previewFileShow"
      :previewFileShow="previewFileShow"
      :currentFile="currentFile"
      ref="previewFile"
    ></PreviewFile>
    <!-- 列表编辑 -->
    <EditFile
      v-if="editFileShow"
      :treeData="treeData"
      nodeKey="value"
      :defaultProps="defaultProps"
      :editFileShow="editFileShow"
      :currentFile="currentFile"
      ref="editFile"
    ></EditFile>
    <!-- 资源分组编辑 -->
    <EditGroup
      v-if="editGroupShow"
      @editSubmitGroup="_editSubmitGroup"
      nodeKey="value"
      :defaultProps="defaultProps"
      :treeData="treeDataGroup"
      :editGroupShow="editGroupShow"
      :currentNode="currentNode"
      pageType="sourceGroup"
      pageValue="otherGroup"
      type="resource"
      ref="editGroup"
    ></EditGroup>
    <!-- 资源分组添加 -->
    <AddGroup
      v-if="addGroupShow"
      @submiAddGroup="_submiAddGroup"
      :defaultProps="defaultProps"
      :treeData="treeData"
      :addGroupShow="addGroupShow"
      pageType="sourceGroup"
      nodeKey="value"
      ref="addGroup"
    ></AddGroup>
    <!-- 分组删除 -->
    <DeleteGroup
    v-if="deleteGroupShow"
    :deleteGroupShow="deleteGroupShow"
    />
    <!-- 资料库 -->
    <DataBank
    v-if="dataBankShow"
    :dataBankShow="dataBankShow"
    pageType="knowledgeBase"
    />
    <!-- 可见范围 -->
    <StaffDepartment
    v-if="staffDepartmentShow"
    :staffDepartmentShow="staffDepartmentShow"
    :currentFile="currentFile"
    />

  </div>
</template>
<script>
import EditFile from './components/editFile.vue'
import EditGroup from '@/components/editGroup/index.vue'
import AddGroup from '@/components/addGroup/index.vue'
import PreviewFile from '@/components/previewFile/index.vue'
import DeleteGroup from '@/components/deleteGroup/index.vue'
import ResourceGrouping from '@/components/resourceGrouping/index.vue'
import StaffDepartment from './components/staffDepartment.vue'
import DataBank from '@/components/dataBank/index.vue'
import tableFirstCol from '@/mixins/tableFirstCol'
import {
  getTreeListApi,
  deleteTreeApi,
  editTreeApi,
  addTreeApi,
  getPageListApi,
  batchDeleteAPI,
  getDetailAPI
} from '@/api/knowledge'
import fileType from '@/mixins/fileType'
export default {
  mixins: [fileType, tableFirstCol],
  components: {
    EditFile,
    EditGroup,
    AddGroup,
    PreviewFile,
    DeleteGroup,
    ResourceGrouping,
    StaffDepartment,
    DataBank
  },
  data () {
    return {
      tableData: [],
      searchForm: {
        groupId: '', // 分组id
        fileName: '', // 文件名
        fileType: '', // 文件类型
        visibilityRange: '', // 可见范围
        time: [],
        page: 1,
        pageSize: 10
      },
      visibilityRangeList: [], // 可见范围
      treeData: [], // 分组
      treeDataGroup: [],
      fileTypeList: [
        {
          name: '图片',
          value: 'image'
        },
        {
          name: '视频',
          value: 'video'
        },
        {
          name: '文档',
          value: 'file'
        }
      ], // 文件类型
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      tableTotal: 0,
      editFileShow: false,
      addGroupShow: false,
      editGroupShow: false,
      previewFileShow: false,
      uploadFileShow: false,
      deleteGroupShow: false,
      selectDepartmentShow: false,
      staffDepartmentShow: false,
      dataBankShow: false,
      currentFile: {}, // 当前行数据
      multipleSelection: [],
      currentFileUrl: ''
    }
  },
  created () {
    this.getTree()
    this.getQuery()
  },
  methods: {

    getQuery (type) {
      if (type) {
        this.searchForm.page = 1
      }
      this.searchForm.createStartTime = this.searchForm.time ? this.searchForm.time[0] : null
      this.searchForm.createEndTime = this.searchForm.time ? this.searchForm.time[1] : null
      getPageListApi(this.searchForm).then(res => {
        if (res.code === 200) {
          this.tableData = res.object.results || []
          this.tableTotal = res.object.total
        }
      })
    },
    getTree () {
      getTreeListApi({ parentId: 0 }).then(res => {
        if (res.code === 200) {
          this.treeData = JSON.parse(JSON.stringify(res.data || []))
        }
      })
    },
    _clickGroupNode (data) {
      // 点击分组查询
      this.searchForm.groupId = data.value
      this.searchForm.page = 1
      this.getQuery()
      // console.log(formatSeconds('22'))
    },

    _editGroupHandle (node) {
      // 分组不能选择子集分组，将当前分组的子集清空--start
      const tempGroup = JSON.parse(JSON.stringify(this.treeData))
      tempGroup.forEach((item, index) => {
        if (node.data.value === item.value) {
          // tempGroup.splice(index, 1)
          item.children = []
        }
        if (item.children && item.children.length !== 0) {
          item.children.forEach((itemChild, childIndex) => {
            if (node.data.value === itemChild.value) {
              // item.splice(childIndex, 1)
              itemChild.children = []
            }
          })
        } else {
          item.children = []
        }
      })
      this.treeDataGroup = tempGroup
      // 分组不能选择子集分组，将当前分组的子集清空--end
      // 资源分组编辑
      this.currentNode = node.data.sourceGroup
      // node.data.children
      this.editGroupShow = true
    },
    _deleteGrouphandle (node) {
      // 资源分组删除
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteTreeApi(node.data.sourceGroup.id).then((res) => {
          if (res.code === 200) {
            this.getTree()
          }
        })
      })
    },
    _addGroupHandle () {
      // 资源分组添加
      this.addGroupShow = true
    },
    _submiAddGroup (item) {
      // 资源分组提交
      const groupNameArr = []
      item.groupNameList.forEach((item) => {
        groupNameArr.push(item.groupName)
      })
      const obj = {
        ...item,
        groupNames: groupNameArr
      }
      addTreeApi(obj).then((res) => {
        if (res.code === 200) {
          this.addGroupShow = false
          this.getTree()
        }
      })
    },
    _editSubmitGroup (item) {
      // 资源分组编辑提交
      const obj = {
        ...item,
        id: this.currentNode.id,
        groupNames: [item.groupName]
      }
      addTreeApi(obj).then((res) => {
        if (res.code === 200) {
          this.editGroupShow = false
          this.getTree()
        }
      })
    },
    _previewFileHandle (row) {
      // 列表预览
      if (row.fileType === 'image' || row.fileType === 'video') {
        this.previewFileShow = true
        this.currentFile = row
      } else {
        const previewUrl = this.getPreviewUrl(row)
        window.open(previewUrl)
      }
    },
    deleteTable (row, type) {
      // 列表删除
      if (type == 1 && !this.multipleSelection.length) {
        this.$message.info('请选择文件！')
        return
      }
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let ids = []

        if (row.id) {
          ids = [row.id]
        } else {
          this.multipleSelection.forEach(item => {
            ids.push(item.id)
          })
        }
        batchDeleteAPI(ids).then(res => {
          if (res.code === 200) {
            this.getQuery()
          }
        })
      })
    },
    handleSelectionChange (val) {
      // 表格勾选状态更换
      this.multipleSelection = val
    },
    _reset () {
      // 重置
      this.searchForm = {
        fileName: '', // 文件名
        fileType: '', // 文件类型
        visibilityRange: '', // 可见范围
        page: 1,
        pageSize: 10,
        groupId: this.searchForm.groupId
      }
      this.getQuery()
    },
    _uploadFileHandle () {
      // this.uploadFileShow = true
      this.$router.push({
        path: '/uploadFile?pageType=knowledgeBase&groupId=' + this.searchForm.groupId
      })
    },
    _editHandle (row) {
      // 列表编辑
      this.editFileShow = true
      this.currentFile = row
    },
    _choosedDepartment (row) {
      // 可见范围
      getDetailAPI({
        id: row.id
      }).then(res => {
        if (res.code === 200) {
          this.staffDepartmentShow = true
          this.currentFile = res.data
        }
      })
    },
    _selectData () {
      // 选择资料库
      this.dataBankShow = true
      this.isViewPDFFileShow = false
    },
    _handleSizeChange (val) {
      this.searchForm.pageSize = val
      this.getQuery()
    },
    _handleCurrentChange (val) {
      this.searchForm.page = val
      this.getQuery()
    }
  }
}
</script>
<style lang="scss" scoped>
.form-item-no-mb {
  /deep/.el-form-item {
    margin-bottom: 0;
  }
}
/deep/.index-list-table {
  thead {
    background-color: #ffffff !important;
  }
  tr {
    height: 51px;
    height: 54px;
    color: rgba(0, 0, 0, 0.9);
    font-size: 14px;
  }
  th {
    background: #f6f7f9 !important;
  }
}
.add-group-btn {
  width: 116px;
  border: 1px solid #0089ff;
  font-size: 14px;
  color: #0089ff;
}
.delete-group-btn {
  width: 60px;
  border: 1px solid #f04134;
  font-size: 14px;
  color: #f04134;
}
/deep/.el-form-item {
  margin-bottom: 16px !important;
  margin-right: 32px !important;
}
/deep/.el-form-item__label{
  color:#000000;
}
/deep/ .el-button {
  font-size: 14px;
  height: 32px;
}
/deep/.el-button--text {
  margin: 0 10px 0px 0!important;
}
.delet-group-btn{
  width: 60px;
}
.knowledgeTable{
  /deep/.el-table__fixed-right{
  .is-leaf:nth-last-child(1){
  div{
      margin-left: 14px!important;
    }
  }
}
}
</style>
